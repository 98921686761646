:root {
  --lightest: #26226220;
  --background: #ffffff;
  --primary200: #262262;
  --primary500: #262262d0;
  --primary900: #26226240;
  --secondary100: #ff1c14;
  --secondary200: #ed1c24;
  --secondary500: #ed1c2440;
  --accent100: #18a038;
  --accent200: #388060;
  --accent500: #38806040;
  --blended200: #848410;
  --blended500: #84841040;
  --blended100: #646420;
  --red: #ed1c24;
  --blue: #262262;
  --green: #388060;
}

html {
  font-size: 14px;
}

body {
  background-color: var(--background);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: normal;
  color: #495057;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.5rem 0 1rem 0;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;
}

h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.75rem;
}
h4 {
  font-size: 1.5rem;
}
h5 {
  font-size: 1.25rem;
}
h6 {
  font-size: 1rem;
}
p {
  line-height: 1.5;
  margin: 0 0 1rem 0;
}

.card {
  margin-bottom: 2rem;
}

/* input[type="number"] {
  -moz-appearance: textfield;
} */

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

/* My custom CSS */
.k-wmin-450px {
  min-width: 450px;
}
.k-w-450px {
  width: 450px;
}

.p-menubar ul {
  margin: auto;
}

.p-field > label.flex-out {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
#quote-designer-card {
  background: #b3e5fc;
}
.p-disabled,
.p-component:disabled {
  background: #f3dddc;
}
.logo-display {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.center-100px {
  width: 100px;
  margin: 0 auto;
}
.w-152px {
  width: 152px;
}
.w-50pct {
  width: 50%;
}
.w-100pct {
  width: 100%;
}
.w-10pct {
  width: 10%;
}
.w-150px {
  width: 150px;
}
.w-200px {
  width: 200px;
}
.k-w-205p5px {
  width: 205.5px;
}
.center-text {
  text-align: center;
}
.background-primary {
  background: #b3e5fc;
  /* margin-left:0.5rem;
	margin-right:0.5rem;
	padding:0; */
}
.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-thead > tr > th {
  text-align: center;
}
.h-20px {
  height: 20px;
}
.h-26px {
  height: 26px;
}
.h-42 {
  height: 42px;
}
.h-160px {
  height: 160px;
}
.h-330px {
  height: 330px;
}
.h-400px {
  height: 400px;
}
.h-min-330px {
  min-height: 330px;
}
.h-min-400px {
  min-height: 400px;
}
.h-100pct {
  height: 100%;
}
.no-margin {
  margin: 0;
}
.mx-auto {
  margin: auto;
}
.reduced-margin {
  margin: 0.25rem 0;
}
.no-border {
  border-style: none;
}

.p-datatable.p-datatable-sm.reduced-height-table .p-datatable-tbody > tr > td,
.reduced-height-table .p-datatable-tbody > tr > td {
  padding: 0.25rem 1rem;
}
.center-header,
.center-title .p-card-title {
  text-align: center;
}
.check-label {
  margin-left: 0.75rem;
  line-height: 1;
}
.p-warning {
  color: #212529;
  background: #ffc107;
  border: 1px solid #ffc107;
}
.p-success {
  color: #ffffff;
  background: #28a745;
  border: 1px solid #28a745;
}
.p-primary {
  color: #ffffff;
  background: #007bff;
  border: 1px solid #007bff;
}
.error-dialog .p-dialog-header,
.error-dialog .p-dialog-content {
  background: #f8d7da;
}
.white-background {
  background-color: #ffffff;
}
.pad-1em {
  padding: 0.5em 2em;
}
.pad-top-half {
  padding-top: 0.5em;
}
.marg-bot-quarter,
.p-grid > .marg-bot-quarter {
  margin-bottom: 0.25rem;
}

/******* Margin ******/
.marg-auto {
  margin: auto;
}
.mx-2em {
  margin-right: 2em;
}
.pad-top-7px {
  padding-top: 7px;
}
.my-p5 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.marg-bot-7px {
  margin-bottom: 7px;
}
.marg-half {
  margin: 0.5rem 0;
}
.mt-1p75r {
  margin-top: 1.75rem;
}
.mt-12px {
  margin-top: 12px;
}
.mt-50px {
  margin-top: 50px;
}
.mt-100px {
  margin-top: 100px;
}
.m-0 {
  margin: 0;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

span.card-param-title {
  font-size: 1.25rem;
  font-weight: 500;
}
#nameOfClient,
#claimOffice,
#claimRepresentative,
#TPA,
#referralFrom {
  padding: 0;
  border: 0;
}
#msaTotal input {
  width: 100%;
}

/* #referralFrom  {
	width:100%;
} */

/***********/
/* New CSS */
/***********/
/* Colors */
.k-opacity-p5 {
  opacity: 0.5;
}
/* Width */
.k-w-38px {
  width: 38px;
}
.k-w-100pct {
  width: 100%;
}
/* Height */
.k-h-34px {
  height: 34px;
}
.k-h-100pct {
  height: 100%;
}
/* Margin */
.k-mt-2r {
  margin-top: 2rem;
}
.k-mr-1r {
  margin-right: 1rem;
}
.k-mb-2r {
  margin-bottom: 2rem;
}
.k-ml-100px {
  margin-left: 100px;
}
.k-ml-p5r {
  margin-left: 0.5rem;
}
.k-my-20px {
  margin-top: 20px;
  margin-bottom: 20px;
}
.k-my-1r {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.k-mx-6px {
  margin-right: 6px;
  margin-left: 6px;
}
.k-mx-10px {
  margin-right: 10px;
  margin-left: 10px;
}
.k-mt-27px {
  margin-top: 27px;
}

/* Paddings */
.px-2 {
  padding: 0 2em;
}
/* Fonts */
.font-title {
  font-size: 2.5em;
  font-weight: 600;
  text-align: center;
}
.font-subtitle {
  font-size: 1.5em;
  font-weight: 800;
  letter-spacing: 1px;
}
.font-l-bold {
  font-size: 16px;
  font-weight: 700;
}
.font-l {
  font-size: 16px;
}

/* Flex */
.k-flex {
  display: flex;
}
.k-flex-start {
  display: flex;
  justify-content: flex-start;
}
.k-flex-center {
  display: flex;
  justify-content: center;
}
.k-flex-between {
  display: flex;
  justify-content: space-between;
}

.k-text-center {
  text-align: center;
}

#login-form input:checked {
  background: #ff0000;
}
#login-form .p-float-label input {
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #224488;
  border-left: none;
  border-radius: 4px 4px 0px 0px;
}
#register-form .p-progress-spinner {
  width: 20px;
  height: 20px;
}
#register-form .pi {
  font-size: 18px;
}
.green-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--green-500);
}
#admin-user .p-datatable .p-datatable-tbody > tr > td {
  /* text-align: left; */
  border: 1px solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 0.25rem 0.25rem;
  outline-color: rgba(38, 143, 255, 0.5);
}

.fake-button {
  width: 395px;
  height: 41px;
  background-color: #dc3545;
  border-radius: 6px;
}
.fake-button-text {
  color: #fff;
  margin: 0;
  font-size: 16px;
  padding-top: 8.25px;
  padding-bottom: 8.25px;
}
.fake-button-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.k-w-1050px {
  width: 1050px;
}

/* .k-flex-between {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem; 
} */
.k-button-space {
  width: 132px;
}
.br-6 {
  border-radius: 6px;
}
.br-9 {
  border-radius: 9px;
}
.pr-4pct {
  padding-right: 4%;
}
.pl-4pct {
  padding-left: 4%;
}
.big-bold-label {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0px;
}
.bold-label {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0px;
}
.color-secondary100 {
  color: var(--secondary100);
}
.p-button.p-button-text.color-secondary100 {
  color: var(--secondary100);
}
.p-button.p-button-text.color-secondary100:hover {
  color: #fff;
  background-color: var(--secondary100);
}
.p-button.p-button-text.color-accent100 {
  color: var(--accent100);
}
.p-button.p-button-text.color-accent100:hover {
  color: #fff;
  background-color: var(--accent100);
}
.p-button.p-button-text.color-blended100 {
  color: var(--blended100);
}
.p-button.p-button-text.color-blended100:hover {
  color: #fff;
  background-color: var(--blended100);
}
.p-button.k-blended {
  color: #fff;
  background-color: var(--blended100);
  border: 1px solid var(--blended100);
}
.p-button.k-blended:hover {
  color: #fff;
  background-color: var(--blended200);
  border: 1px solid var(--blended100);
}
.k-flex > .position-right {
  margin-right: auto;
}
.opacity-50 {
  opacity: 0.5;
}
/* tooltips */
.blue-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--blue);
}
.blue-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--blue);
}
.red-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--red);
}
.red-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--red);
}
.green-tooltip.p-tooltip .p-tooltip-arrow {
  border-top-color: var(--green);
}
.green-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--green);
}
.green-tooltip.p-tooltip .p-tooltip-text > a {
  text-decoration: none;
  color: white;
}
.green-tooltip.p-tooltip .p-tooltip-text > a:visited {
  text-decoration: none;
  color: white;
}
