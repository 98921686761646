/************************************/
/*   Brokers and Claimants Pages    */
/************************************/
.claimant-page-container,
.broker-page-container {
  padding: 2em;
  border-radius: 9px;
  border: solid 2px var(--primary200);
}
.view-saved-proposals,
.view-claimants,
.add-claimant-form,
.view-brokers,
.add-broker-form {
  padding: 2em;
  border-radius: 9px;
  border: solid 2px var(--primary200);
  background-color: var(--lightest);
}
.inner-saved-proposals-table .p-datatable .p-datatable-thead,
.view-saved-proposals .p-datatable .p-datatable-header,
.view-brokers .p-datatable .p-datatable-header,
.view-claimants .p-datatable .p-datatable-header {
  padding: 0;
  border-radius: 9px 9px 0 0;
}
.inner-saved-proposals-table,
.view-saved-proposals-table,
.view-brokers-table,
.view-claimants-table {
  /* border: solid 1px var(--primary200); */
  border-radius: 9px;
}
.saved-proposals-table-header,
.broker-table-header,
.claimant-table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 9px 9px 0 0;
  background-color: var(--primary900);
}
.saved-proposals-header p,
.broker-table-header p,
.claimant-table-header p {
  margin: 0;
  /* color: var(--primary200); */
}
.view-saved-proposals .p-datatable .p-datatable-thead > tr > th,
.view-brokers .p-datatable .p-datatable-thead > tr > th,
.view-claimants .p-datatable .p-datatable-thead > tr > th {
  color: black;
  width: 12em;
  background: var(--primary900);
  border-top: solid 1px var(--primary200);
  border-bottom: solid 1px var(--primary200);
}
.view-saved-proposals .p-datatable .p-sortable-column .p-sortable-column-icon,
.view-brokers .p-datatable .p-sortable-column .p-sortable-column-icon,
.view-claimants .p-datatable .p-sortable-column .p-sortable-column-icon {
  color: black;
}
.view-saved-proposals .p-paginator,
.view-brokers .p-paginator,
.view-claimants .p-paginator {
  border-radius: 0 0 9px 9px;
}

/************************************/
/*      Claimants Page              */
/************************************/
/* .claimant-page-container {
  padding: 2em;
  border-radius: 9px;
  border: solid 2px var(--primary200);
} */
/* .view-claimants,
.add-claimant-form {
  padding: 2em;
  border-radius: 9px;
  border: solid 2px var(--primary200);
  background-color: var(--lightest);
} */
.view-claimants .p-datatable .p-datatable-header {
  padding: 0;
  border-radius: 9px 9px 0 0;
}
.view-claimants-table {
  /* border: solid 1px var(--primary200); */
  border-radius: 9px;
}
.claimant-table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 1rem;
  border-radius: 9px 9px 0 0;
  background-color: var(--primary900);
}
.claimant-table-header p {
  margin: 0;
  /* color: var(--primary200); */
}

.view-claimants .p-datatable .p-datatable-thead > tr > th {
  color: black;
  width: 12em;
  background: var(--primary900);
  border-top: solid 1px var(--primary200);
  border-bottom: solid 1px var(--primary200);
}
.view-claimants .p-datatable .p-sortable-column .p-sortable-column-icon {
  color: black;
}
.view-claimants .p-paginator {
  border-radius: 0 0 9px 9px;
}
/************************************/
/*      Saved Proposals Page        */
/************************************/
.saved-proposals-page-container {
  padding: 2em;
  border-radius: 9px;
  border: solid 2px var(--primary200);
}
.saved-proposals-claimants {
  padding: 2em;
  border-radius: 9px;
  border: solid 2px var(--primary200);
  background-color: var(--lightest);
}
.saved-proposals-table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/************************************/
/*      Settlement Analyzer Page    */
/************************************/
.settlement-analyzer-setup {
  padding: 2em;
  border-radius: 9px;
  border: solid 2px var(--primary200);
}
.p-button.bg-accent200 {
  background: var(--accent200);
  border: none;
}
.p-button.bg-accent200:hover {
  background: var(--accent100);
  border: none;
}
.p-button.bg-secondary200 {
  background: var(--secondary200);
  border: none;
}
.p-button.bg-secondary200:hover {
  background: var(--secondary100);
  border: none;
}
/* broker and claimant */
.broker-claimant {
  background-color: var(--primary200);
  color: #f0f0f0;
  border-radius: 9px;
}
.broker-claimant.p-card .p-card-title {
  font: bold;
  text-align: center;
  letter-spacing: 0.1rem;
  font-size: 24px;
}
/* Analyzer Setup Tabs */
.settlement-analyzer-setup
  .p-tabview
  .p-tabview-nav
  li.p-highlite
  .p-tabview-nav-link,
.settlement-analyzer-setup .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: none;
}
.settlement-analyzer-setup .p-tabview .p-tabview-nav li.p-highlite,
.settlement-analyzer-setup .p-tabview .p-tabview-nav li {
  margin-right: 0.4em;
}
#tab-view-stock .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #ffffff;
  background: var(--primary200);
  width: 12em;
}
#tab-view-stock .p-tabview-nav li .p-tabview-nav-link {
  color: #f0f0f0b0;
  background: var(--primary500);
  width: 12em;
}
#tab-view-extra .p-tabview-nav li > a > span,
#tab-view-stock .p-tabview-nav li > a > span {
  margin: auto;
}
#tab-view-extra .p-tabview-nav li:first-child .p-tabview-nav-link {
  color: #ffffff;
  width: 12em;
  background: var(--secondary200);
}
#tab-view-extra .p-tabview-nav li:nth-child(2) .p-tabview-nav-link {
  color: #ffffff;
  width: 12em;
  background: var(--accent200);
}
#tab-view-extra .p-tabview-nav li:nth-child(3) .p-tabview-nav-link {
  color: #ffffff;
  width: 12em;
  background: var(--blended200);
}
.p-tabview .p-tabview-panels {
  padding: 0;
  border-radius: 9px;
}
#tab-view-extra > .p-tabview-nav,
#tab-view-stock > .p-tabview-nav {
  border: none;
}
.stock-calculator {
  background-color: var(--primary200);
  color: #f0f0f0;
  border-radius: 0 9px 9px 9px;
  min-height: 340px;
}
.border-secondary-1 {
  border: var(--secondary200) solid 2px;
}
.bg-secondary100 {
  background: var(--secondary100);
}
.k-horizontal-input .p-fluid .p-input-number,
.k-horizontal-input > .p-field > label {
  display: block;
}
.stock-calculator.p-card > .p-card-body {
  padding: 0 1.5rem;
}
.stock-calculator.p-card .p-card-content {
  padding: 1.5rem 0 0 0;
}
/*************Draws and Income Tables ***********************/
#variable-draws-table thead > tr > th {
  background-color: var(--secondary200);
  color: #f0f0f0;
  font-weight: 700;
}
#variable-draws-table tbody tr td {
  background-color: var(--secondary500);
  color: var(--primary200);
  font-weight: 700;
}
#variable-incomes-table thead > tr > th {
  background-color: var(--accent200);
  color: #f0f0f0;
  font-weight: 700;
}
#variable-incomes-table tbody tr td {
  background-color: var(--accent500);
  color: var(--primary200);
  font-weight: 700;
}
#variable-blendeds-table thead > tr > th {
  background-color: var(--blended200);
  color: #f0f0f0;
  font-weight: 700;
}
#variable-blendeds-table tbody tr td {
  background-color: var(--blended500);
  color: var(--primary200);
  font-weight: 700;
}
/************ Settlement Analyzer Results ***********************/
.analyzer-results {
  padding: 0 2em;
  border-radius: 9px;
  border: solid 2px var(--primary200);
}
.k-results-tab .p-tabmenu-nav {
  justify-content: center;
  margin-bottom: 1rem;
}
.k-tab-menu-nav .p-tabmenu-nav,
.k-analyzer-results-tab .p-tabmenu-nav {
  justify-content: center;
  border-bottom: 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #d0d0d0;
}
.analyzer-conditions {
  background-color: var(--primary200);
  color: #f0f0f0;
  border-radius: 9px;
}
.analyzer-passing {
  background-color: var(--accent200);
  color: #f0f0f0;
  border-radius: 9px;
}
.analyzer-failing {
  background-color: var(--secondary200);
  color: #f0f0f0;
  border-radius: 9px;
}
.field-header {
  font-size: 1em;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 0.25em 0;
}
.field-value {
  font-size: 1em;
  text-align: center;
}
.card.pad-top-half {
  margin-bottom: 0.25em;
}

/*
.p-orange-border {
  border: 2px solid var(--orange-400);
}
.broker-claimant {
  background-color: var(--orange-200);
}
.stock-calculator {
  background-color: var(--orange-200);
}
#tab-view-stock > ul > li > a {
  background: var(--orange-200);
}
#tab-view-calculator > ul > li:nth-child(1) > a {
  background: var(--pink-200);
}
#tab-view-calculator > ul > li:nth-child(2) > a {
  background: var(--green-200);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link,
.p-tabview .p-tabview-nav {
  border: 0;
}
#dialog-add-contact .p-dialog-header {
  background-color: var(--blue-200);
}
#tab-panel-draws {
  background-color: var(--pink-100);
}
#tab-panel-incomes,
#tab-panel-benefits {
  background-color: var(--green-100);
}

.annuity-calculator {
  background-color: var(--orange-200);
}


.annuity-calculator > .p-card-body,
.annuity-calculator > .p-card-body > .p-card-content {
  padding-bottom: 0;
}

*/
/* #tab-view-stock > ul > li > a {
  background: var(--primary200);
  color: #f0f0f0;
} */
/* #tab-panel-draws {
  background-color: var(--pink-100);
}
#tab-panel-incomes,
#tab-panel-benefits {
  background-color: var(--green-100);
} */
/* #calculator-income-table thead > tr > th,
#calculator-benefit-table thead > tr > th {
  background-color: var(--green-200);
}
#calculator-income-table tbody tr td,
#calculator-benefit-table tbody tr td {
  background-color: var(--green-100);
}
#variable-draws-table thead > tr > th,
#calculator-draw-table thead > tr > th {
  background-color: var(--pink-200);
}
#variable-draws-table tbody tr td,
#calculator-draw-table tbody tr td {
  background-color: var(--pink-100);
}
#variable-incomes-table thead > tr > th,
#calculator-income-table thead > tr > th,
#variable-benefits-table thead > tr > th,
#calculator-benefit-table thead > tr > th {
  background-color: var(--green-200);
}
#variable-incomes-table tbody tr td,
#calculator-income-table tbody tr td,
#variable-benefits-table tbody tr td,
#calculator-benefit-table tbody tr td {
  background-color: var(--green-100);
} */
/* .border-black-1 {
  border: 1px solid var(--orange-500);
}
.c-red {
  color: var(--pink-500);
}
.c-green {
  color: var(--green-500);
} */
